<template>
    <div class="principal">
        <LoaderDefault v-if="isLoading" />
        <div v-if="!isLoading" class="page-header back-image4"></div>
        <div v-if="!isLoading" class="col-md-1">
            <div class="wrapper-content bg-white2 pinside40">
                <div class="container-transparencia">
                    <a-row class="obj">
                        <div class="col-lg-8">
                            <div class="col-12">
                                <div class="d-flex align-items-center justify-content-between bg-light py-2 px-4 mb-3">
                                    <h3 class=" h3 m-0">Noticias</h3>
                                </div>
                            </div>
                            <div class="col-lg-6" v-for="(item, index) in listAllNews" :key="index">
                                <div class="position-relative mb-3">
                                    <img class="img-fluid w-100" :src="item.images[0].urlImage" style="object-fit: cover;">
                                    <div class="overlay position-relative bg-light">
                                        <div class="mb-2" style="font-size: 14px;">
                                            <a class="tx" href="">Noticia</a>
                                            <span class="px-1">/</span>
                                            <span><small>{{ item.date }}</small></span>
                                        </div>
                                        <router-link class="a h4"
                                            :to="{ name: 'publicDetailNew', params: { _id: item._id } }">{{
                                                item.title
                                            }}</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class=" alinear-text col-lg-4 pt-3 pt-lg-0">
                            <div class="mb-3 pb-3">
                                <a href=""><img class="img-fluid" src="../../../assets/img/imagen2-news.png" alt=""></a>
                            </div>
                            <div class="pb-3">
                                <div class="bg-light py-2 px-4 mb-3">
                                    <h3 class=" h3 m-0">Infórmate</h3>
                                </div>
                                <div class="d-flex mb-3" v-for="(item, index) in listNewsRandom" :key="index">
                                    <img style="width: 100px; height: 100px; object-fit: cover;"
                                        :src="item.images[0].urlImage">
                                    <div class="w-100 d-flex flex-column justify-content-center bg-light px-3"
                                        style="height: 100px;">
                                        <div class="mb-1" style="font-size: 13px;">
                                            <a class="tx" href="">Noticia</a>
                                            <span class="px-1">/</span>
                                            <span>{{ item.date }}</span>
                                        </div>
                                        <router-link class="a h6 m-0"
                                            :to="{ name: 'publicDetailNew', params: { _id: item._id } }">{{
                                                item.title
                                            }}</router-link>
                                    </div>
                                </div>
                            </div>

                            <div class="pb-3">
                                <div class="bg-light py-2 px-4 mb-3">
                                    <h3 class=" h3 m-0">Sitios Web</h3>
                                </div>
                                <div class="d-flex flex-wrap m-n1">
                                    <a href="https://twitter.com/EmsilviaESP " target="_blank"
                                        class="btn btn-sm btn-outline-secondary m-1">Twitter
                                        <a-icon type="twitter" class="twitter" />
                                    </a>
                                    <a href="https://www.facebook.com/profile.php?id=100093843576095" target="_blank"
                                        class="btn btn-sm btn-outline-secondary m-1">Facebook
                                        <a-icon type="facebook" theme="filled" />
                                    </a>
                                    <a href="https://www.instagram.com/emsilvia_esp/" target="_blank"
                                        class="btn btn-sm btn-outline-secondary m-1">Instagram
                                        <a-icon type="instagram" theme="filled" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </a-row>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import { db } from '../../../../firebaseConfig';
import LoaderDefault from '../../../components/Loaders/LoaderDefault';

export default {
    components: {
        LoaderDefault
    },
    data() {
        return {
            listAllNews: [],
            isLoading: false,
            listNewsRandom: [],
        };
    },
    async mounted() {
        await this.getAllNews();
        this.getAllNews()
    },
    methods: {
        async getAllNews() {
            try {
                this.isLoading = true;
                const res = await db.collection('news').get();
                const news = [];
                res.forEach((doc) => {
                    news.push(doc.data());
                });
                this.listAllNews = news;
                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;
            }
        },

        async getAllNews() {
            this.isLoading = true;
            await db.collection('news').get().then((res) => {
                const news = [];
                res.forEach((doc) => {
                    news.push(doc.data());
                });
                this.listAllNews = news;
                const listaCopia = this.listAllNews.slice();
                if (listaCopia.length <= 3) {
                    this.listNewsRandom = [...listaCopia];
                } else {
                    this.listNewsRandom = listaCopia.sort(() => Math.random() - 0.5).slice(0, 3);
                }
                this.isLoading = false;
            });
        },
    }
};


</script>
<style lang="css">
.back-image4 {
    background: url('../../../assets/img/fotoNews.png') no-repeat center;
    background-size: cover;
}

.bg-white2 {
    background-color: #eaf5fd;

}

@media (min-width: 992px) {
    .col-lg-8 {
        flex: 1 0 50%;
        max-width: 90%;

    }
}


@media (min-width: 1200px) {
    .col-lg-4 {
        width: 33.33333333%;
        float: left;
    }
}

@media (min-width: 1200px) {
    .col-lg-4:nth-child(3n+1) {
        clear: left;
    }
}

.col-lg-8,
.col-12,
.col-lg-6 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

@media(max-width:400px) {

    .col-lg-8,
    .col-12,
    .col-lg-6 {
        padding-left: 0px;
        padding-right: 0px;
    }
}



.px-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
}

.py-2 {
    padding-bottom: 0.5rem !important;
    padding-top: 0.5rem !important;
}

.mb-3 {
    margin-bottom: 1rem !important;
    border-radius: 16px;
    box-shadow: rgb(156, 155, 155) 4px 4px 18px 1px;

}

.d-flex {
    display: flex !important;
}

.h3 {
    font-weight: 700 !important;
    font-size: 1.75rem;
    line-height: 1.2;
    color: rgb(52, 58, 64);
}

.position-relative {
    position: relative !important;

}

.w-100 {
    width: 100% !important;
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

.overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 30px;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    background: rgba(0, 0, 0, 0.3);
    z-index: 1;
    padding-top: 10px;
}

@media(max-width:400px) {
    .overlay {
        padding-right: 0px;
        padding-left: 0px;
    }
}

.a {
    color: var(--colorBluePrimary);
    text-decoration: none;
    background-color: transparent;
}

.tx {
    color: var(--colorBluePrimary);
    pointer-events: none;

}

.px-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
}


.a:hover {
    color: var(--colorBluePrimary);
    text-decoration: underline;
}

.h6,
.h4 {
    font-weight: 500;
    line-height: 1.2;
    color: rgb(52, 58, 64);

}


.px-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    padding-bottom: 1rem !important;
    padding-top: 1rem !important;
}

.flex-column {
    flex-direction: column !important;
}


.obj {
    display: flex;

}

@media (max-width: 650px) {
    .alinear-text {
        display: none;
    }

}
</style>